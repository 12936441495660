import { Fragment, useEffect, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Research = () => {
    const [inputval, setInputVal] = useState("");
    const [typeval, setTypeVal] = useState("genre");
    const [albumSearch, setAlbumSearch] = useState([]);
    const [genreSearch, setGenreSearch] = useState([]);
    const [artistSearch, setArtistSearch] = useState([]);
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const navigate = useNavigate();
    useEffect(() => {
        async function getData() {
            if (inputval.length === 0) {
                setAlbumSearch([]);
                setGenreSearch([]);
                setArtistSearch([]);
            }
            if (inputval.length <= 2)
                return
            const { data } = await axios("http://spotiapi.dayoo.ovh/search?query=" + inputval + "&type=" + typeval)
            await delay(1000);
            if (typeval === "album")
                setAlbumSearch(data.albums);
            if (typeval === "genre")
                setGenreSearch(data.genres);
            if (typeval === "artist")
                setArtistSearch(data.artists);
            if (inputval.length === 0) {
                setGenreSearch([]);
                setAlbumSearch([]);
                setArtistSearch([]);
            }
        }
        getData();
    }, [inputval, typeval, albumSearch, genreSearch]);

    const handleInput = (event) => {
        setInputVal(event.target.value);
    };
    const handleChange = (event) => {
        setTypeVal(event.target.value);
    };
    return (
        <Fragment>
            <input onInput={handleInput} type="text" className="research" placeholder="Research"></input>
            <select onChange={handleChange} className="what">
                <option value={"genre"}>Genre</option>
                <option value={"artist"}>Artists</option>
                <option value={"album"}>Albums</option>
            </select>
            <div class="list-albums">
                {
                    albumSearch.map((item) => {

                        return <Fragment key={item.id}>
                            <div class="album" onClick={() => navigate("/albumsinfo/" + item.id)}>
                                <div class="album-img">
                                    <img alt={item.name} src={item.cover}></img>
                                </div>
                                <div class="album-name">
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        </Fragment>

                    })

                }
            </div>
            <div className="gender-all">
                {

                    genreSearch.map((item) => {
                        return <Fragment key={item.id}>
                            <div className="gender-gender" onClick={() => navigate("/genreinfo/" + item.id)}>
                                <h2>{item.name}</h2>
                            </div>

                        </Fragment>
                    })
                }
            </div>
            <div className="artist-all">
                {
                    artistSearch.map((item) => {
                        return <Fragment key={item.id}>
                            <div className="artist-artist" onClick={() => navigate("/artistinfo/" + item.id)}>
                                <div className="artist-photo">
                                    <img alt={item.name} src={item.photo}></img>
                                </div>
                                <h2>{item.name}</h2>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </Fragment>
    );
}
export default Research