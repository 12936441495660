import { Fragment, useEffect, useState } from "react"
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ArtistsInfo = () => {

    const [artistsInfo, setArtistInfo] = useState([]);
    const navigate = useNavigate()

    // const [artistAlbums, setArtistAlbums] = useState([]);
    let { id } = useParams()
    useEffect(() => {
        async function getData() {
            const { data } = await axios("http://spotiapi.dayoo.ovh/artists/" + id);
            setArtistInfo(data);
        }
        getData();
    }, [id]);

    const [albums, setAlbumsInfo] = useState([]);
    useEffect(() => {
        async function getData() {
            const { data } = await axios("http://spotiapi.dayoo.ovh/albums/artist/" + id);
            setAlbumsInfo(data);
        }
        getData();
    }, [id])

    return <Fragment>
        <div class="container-artists-info">
            <div class="flex">

                <img src={artistsInfo.photo} alt={artistsInfo.name} />
                <div>

                    <h2>{artistsInfo.name}</h2>
                    <br></br>
                    <p>{artistsInfo.bio}</p>
                </div>
            </div>
            <p>{artistsInfo.description}</p><br />
            <h2>Albums</h2>
            {/* <h4>{artistAlbums.name}</h4> */}
        </div>
        <div class="container-albums">
            <div class="contain-buttons">
            </div>
            <div class="list-albums">
                {
                    albums.map((item) => {
                        return <Fragment key={item.id}>
                            <div class="album" onClick={() => navigate("/albumsinfo/" + item.id)}>
                                <div class="album-img">
                                    <img alt={item.name} src={item.cover}></img>
                                </div>
                                <div class="album-name">
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </div>
    </Fragment>

}
export default ArtistsInfo