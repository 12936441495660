import React, { Fragment } from 'react';
// logo :
import { AiFillHome } from 'react-icons/ai';
import { BsSearchHeartFill } from 'react-icons/bs';
import { BiLibrary } from 'react-icons/bi';
const Navbar = () => {
    //render :
    return (
    <Fragment>
        <header>
            <nav>
                <ul>
                    <li><a href="/"><img src='./logo-spotify.png' alt="" /></a></li>
                    <div className="nav-header-li">
                        <li><a href="/"><div className='nav-header-icon'>< AiFillHome /></div>Accueil</a></li>
                        <li><a href="/research"><div className='nav-header-icon'>< BsSearchHeartFill /></div>Rechercher</a></li>
                        <li><a href="/albums"><div className='nav-header-icon'><BiLibrary /></div>Albums</a></li>
                        <li><a href="/artists"><div className='nav-header-icon'><BiLibrary /></div>Artists</a></li>
                    </div>
                </ul>
            </nav>
        </header>
    </Fragment>
    );
}

export default Navbar;
