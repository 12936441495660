import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Fragment } from 'react';
import Home from './components/Home';
import Albums from './components/Albums';
import Genres from './components/Genres';
import Artists from './components/Artists';
import Navbar from './components/nav-header';
import NavFooter from './components/nav-footer';
import AlbumInfo from './components/Albuminfo';
import ArtistsInfo from './components/Artistinfo';
import Genreinfo from './components/Genreinfo';
import Research from './components/research';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/albums",
    element: <Albums />,
  },
  {
    path: "/genres",
    element: <Genres />,
  },
  {
    path: "/artists",
    element: <Artists />,
  },
  {
    path: "/albumsinfo/:id",
    element: <AlbumInfo />,
  },
  {
    path: "/artistinfo/:id",
    element: <ArtistsInfo />,
  },
  {
    path: "/genreinfo/:id",
    element: <Genreinfo />,
  },
  {
    path: "/research",
    element: <Research />,
  },
]);


function App() {

  return (
    <Fragment>
      <div class="container-main">
        <div class="nav-bar">
      <Navbar />
        </div>
        <div class="right-part">
          <RouterProvider router={router} />
        </div>
      </div>
        <NavFooter />
    </Fragment>
  );

}

export default App;
