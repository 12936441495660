import { Fragment, useEffect, useState } from "react"
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";



const Genreinfo = () => {
    const navigate = useNavigate()
    const [genreInfo, setGenreInfo] = useState([]);
    let { id } = useParams()
    useEffect(() => {
        async function getData() {
            const { data } = await axios("http://spotiapi.dayoo.ovh/genres/" + id);
            setGenreInfo(data.albums);
        }
        getData();
    }, [id]);

    const [albumsInfo, setAlbumsInfo] = useState([]);

    useEffect(() => {
        async function getData() {
            const promise = genreInfo.map(async (item) => {
                const { data } = await axios("http://spotiapi.dayoo.ovh/albums/" + item);
                return data;
            });
            const albums = await Promise.all(promise);
            setAlbumsInfo(albums);
        }
        getData();
    }, [genreInfo])
    return <Fragment>
        <div class="container-albums">
            <div class="contain-buttons">
            </div>
            <div class="list-albums">
                {
                    albumsInfo.map((item) => {
                        return <Fragment key={item.album.id}>
                            <div class="album" onClick={() => navigate("/albumsinfo/" + item.album.id)}>
                                <div class="album-img">
                                    <img alt={item.album.name} src={item.album.cover}></img>
                                </div>
                                <div class="album-name">
                                    <p>{item.album.name}</p>
                                </div>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </div>
    </Fragment>
}
export default Genreinfo