import { Fragment, useEffect, useState } from "react"
import axios from "axios";
import { useParams } from "react-router-dom";
const AlbumInfo = () => {

    const [albumInfo, setAlbumInfo] = useState([]);
    const [tracksInfo, setTracksInfo] = useState([]);
    const [trackPlay, setTrackPlay] = useState("");
    let { id } = useParams()
    useEffect(() => {
        async function getData() {
            const { data } = await axios("http://spotiapi.dayoo.ovh/albums/" + id);
            setAlbumInfo(data.album);
            setTracksInfo(data.tracks)
        }
        getData();
    }, [id]);

    useEffect(() => {
        var audio = document.querySelector("source");
        audio.src = trackPlay;
        document.querySelector("audio").load();
    }, [trackPlay])

    return <Fragment>
        <div class="container-album-info">
            <div class="flex">
                <img src={albumInfo.cover} alt={albumInfo.name} />
                <div>
                    <h2>{albumInfo.name}</h2>
                    <br></br>

                    <p>{albumInfo.description}</p>
                </div>
            </div>
            <div class="container-tracks">
                {
                    tracksInfo.map((item) => {
                        return <Fragment key={item.id}>
                            <div onClick={() => setTrackPlay(item.mp3)} class="track">
                                <span>{item.track_no}</span>
                                <span class='track-item'>{item.name}</span>
                                <span>{Math.round(item.duration / 60 * 100) / 100}</span>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </div>
    </Fragment>

}
export default AlbumInfo