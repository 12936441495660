import { Fragment, useEffect, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Genres = () => {

    const [genres, setGenres] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        async function getData() {
            const { data } = await axios("http://spotiapi.dayoo.ovh/genres", { params: { limit: 10, page: 1 } });
            setGenres(data);
        }
        getData();
    }, []);

    return <Fragment>
        <h1 class="titre">Genres</h1>

        <div className="gender-all">

            {

                genres.map((item) => {
                    return <Fragment key={item.id}>
                        <div className="gender-gender" onClick={() => navigate("./genreinfo/" + item.id)}>
                            <h2>{item.name}</h2>
                        </div>

                    </Fragment>
                })
            }
        </div>
    </Fragment>
}
export default Genres