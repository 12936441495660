import { Fragment, useEffect, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ImArrowRight2 } from "react-icons/im";
import { ImArrowLeft2 } from "react-icons/im";

const Albums = () => {

    const [albums, setAlbums] = useState([]);
    const [page, setPage] = useState(Math.floor(Math.random() * 1625 / 10));
    const navigate = useNavigate()
    useEffect(() => {
        async function getData() {
            const { data } = await axios("http://spotiapi.dayoo.ovh/albums", { params: { limit: 10, page: page } });
            setAlbums(data);
        }
        getData();
    }, [page]);



    return <Fragment>
        <div class="container-albums">
            <div class="contain-buttons">
                <button onClick={() => setPage(page <= 1 ? 1 : page - 1)} className="button left"><ImArrowLeft2 /></button>
                <button onClick={() => setPage(page + 1)} className="button right"><ImArrowRight2 /></button>
            </div>
            <h1 class="titre">Albums</h1>
            <div class="list-albums">
                {
                    albums.map((item) => {
                        return <Fragment key={item.id}>
                            <div class="album" onClick={() => navigate("/albumsinfo/" + item.id)}>
                                <div class="album-img">
                                    <img alt={item.name} src={item.cover}></img>
                                </div>
                                <div class="album-name">
                                    <h2>{item.name}</h2>
                                </div>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </div>
    </Fragment>
}
export default Albums