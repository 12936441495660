import { Fragment, useEffect, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ImArrowRight2 } from "react-icons/im";
import { ImArrowLeft2 } from "react-icons/im";


const Artists = () => {

    const [genres, setGenres] = useState([]);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    useEffect(() => {
        async function getData() {
            const { data } = await axios("http://spotiapi.dayoo.ovh/artists", { params: { limit: 10, page } });
            setGenres(data);
        }
        getData();
    }, [page]);

    return <Fragment>
        <div class="contain-buttons">
            <button onClick={() => setPage(page <= 1 ? 1 : page - 1)} className="button left"><ImArrowLeft2 /></button>
            <button onClick={() => setPage(page + 1)} className="button right"><ImArrowRight2 /></button>
        </div>
        <h1 class="titre">Artists</h1>
        <div className="artist-all">
            {
                genres.map((item) => {
                    return <Fragment key={item.id}>
                        <div className="artist-artist" onClick={() => navigate("/artistinfo/" + item.id)}>
                            <div className="artist-photo">
                                <img alt={item.name} src={item.photo}></img>
                            </div>
                            <h2>{item.name}</h2>
                        </div>
                    </Fragment>
                })
            }
        </div>
    </Fragment>
}
export default Artists