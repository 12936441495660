import { Fragment } from "react"
import Albums from "./Albums"
import Artists from "./Artists"
import Genres from "./Genres"
const Home = () => {

    return <Fragment>
        <Albums/>
        <Artists/>
        <Genres/>
    </Fragment>
}
export default Home