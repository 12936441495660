import React, { Fragment } from 'react';
import ReactAudioPlayer from 'react-audio-player';


const NavFooter = () => {
    //render :
    return (
        <Fragment>
            <footer>
                <div className="footer-btn">
                    <ReactAudioPlayer 
                    id="player"
                    autoPlay="true"
                    controls="true"><source type="audio/mp3"></source></ReactAudioPlayer>
                </div>
            </footer>
        </Fragment>
    );

}

export default NavFooter;